import { IToolType, ITool, platform } from "@/types/toolCollection";

import wisWebsiteDisplay from "../../../public/img/tools/whereIsXur/websiteDisplay.png"
import wisHome from "../../../public/img/tools/whereIsXur/home.png"
import wisXurDetails from "../../../public/img/tools/whereIsXur/xurDetails.png"

export const whereIsXur: ITool = {
	title: "Where is Xur",
	description: "Find where is xur is, what his inventory is and how long he will be staying",
  platforms: [platform.Website],
  link: "https://whereisxur.com/",
	image: wisWebsiteDisplay,
	imageMobile: wisWebsiteDisplay,
  images: [wisHome, wisXurDetails]
};


import appWebsiteDisplay from "../../../public/img/tools/xurApp/websiteDisplay.webp"
import appItems from "../../../public/img/tools/xurApp/items.webp"
import appItemDisplay1 from "../../../public/img/tools/xurApp/itemDisplay1.webp"
import appItemDisplay2 from "../../../public/img/tools/xurApp/itemDisplay2.webp"
import appReview from "../../../public/img/tools/xurApp/review.webp"
import appArchive from "../../../public/img/tools/xurApp/archive.webp"

export const xurApp: ITool = {
	title: "Where is Xur? app",
	description: "Find xur using your phone and get a detailed description of what his inventory is",
  platforms: [platform.Mobile],
  link: "https://play.google.com/store/apps/details?id=com.crocusgames.whereisxur&hl=en&gl=US",
	image: appWebsiteDisplay,
	imageMobile: appWebsiteDisplay,
  images: [appWebsiteDisplay, appItems, appItemDisplay1, appItemDisplay2, appReview, appArchive]
};


import fxWebsiteDisplay from "../../../public/img/tools/findXur/websiteDisplay.png"
import fxHome from "../../../public/img/tools/findXur/page.png"

export const findXur: ITool = {
	title: "Find xur",
	description: "Find where is xur is and what is inventory is",
  platforms: [platform.Website],
  link: "https://ftw.in/game/destiny-2/find-xur",
	image: fxWebsiteDisplay,
	imageMobile: fxHome,
  images: [fxHome]
};

const xurLocator: IToolType = {
	name: "Xur Locator",
	tools: [whereIsXur, xurApp, findXur],
};

export default xurLocator;

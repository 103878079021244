
export type IConfig = {
  apiKey: string,
  clientId: string,
}

export const devConfig = {
  apiKey: '923d7b41c1794173a8d9d5e278874fa4',
  clientId: '35015',
}

export default (process.env.NODE_ENV === "development") ? devConfig : {
  apiKey: '835656078edf473abfa475b6bd1d2692',
  clientId: '34002',
}

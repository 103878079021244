











import { Component, Vue } from "vue-property-decorator";
import Legend from "../components/home/legend.vue";
import ToolDisplay from "../components/home/toolsDisplay.vue";
import Scrollbar from "../components/home/scrollbar.vue";
import tools from "../data/toolCollections";

@Component({
	components: {
		displayLegend: Legend,
		display: ToolDisplay,
		scrollbar: Scrollbar,
	},
})
export default class Home extends Vue {
	tools = tools;

	mounted(): void {
		this.$store.commit('setRoute', this.$route.path);
		this.$emit("ChangeBackground", "home");
		console.log(this.$store.getters.getAuthState);
	}

	relayCollectionChange(collectionId: number) {
		(this.$refs.legend as Legend).activeButton(collectionId);
	}

	relayGotoCollection(collectionId: number) {
		(this.$refs.display as ToolDisplay).scrollTo(collectionId);
	}
}

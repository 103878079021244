import OAuth from "@/util/OAuth"
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			title: "Destiny Tools - Home"
		}
	},
	{
		path: "/leveler",
		name: "Leveling",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ "../views/Leveler.vue"),
		meta: {
			title: "Destiny Tools - Level Calculator"
		}
	},
	{
		path: "/bungie/callback",
		name: "OAuth Response",
		component: Vue.extend({
			async mounted() {
				const pathQueries = this.$route.query as { [index: string]: string };
				if (pathQueries.code && pathQueries.state) await OAuth.logInCallback(pathQueries.code, pathQueries.state);
				this.$router.push(this.$store.state.route);
			}
		})
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router;

import { IToolType, ITool, platform } from "@/types/toolCollection";

import d2CheckWebsiteDisplay from "../../../public/img/tools/d2Checklist/websiteDisplay.png"
import d2CheckHub from "../../../public/img/tools/d2Checklist/hub.png"
import d2CheckChecklist from "../../../public/img/tools/d2Checklist/checklist.png"
import d2CheckWeaponList from "../../../public/img/tools/d2Checklist/weaponList.png"
import d2CheckTriumphs from "../../../public/img/tools/d2Checklist/triumphs.png"

export const d2Checklist: ITool = {
	title: "D2 Checklist",
	description: "Track everything you have left to do inside Destiny 2 and create your own TODO's",
  platforms: [platform.Website],
  link: "https://www.d2checklist.com/home",
	image: d2CheckWebsiteDisplay,
	imageMobile: d2CheckWebsiteDisplay,
  images: [d2CheckHub, d2CheckChecklist, d2CheckWeaponList, d2CheckTriumphs]
};


import seasonWebsiteDisplay from "../../../public/img/tools/seasonChecklist/websiteDisplay.png"
import seasonMobileDisplay from "../../../public/img/tools/seasonChecklist/mobileDisplay.png"
import seasonXp from "../../../public/img/tools/seasonChecklist/xp.png"
import seasonMaterial from "../../../public/img/tools/seasonChecklist/material.png"
import seasonGlimmer from "../../../public/img/tools/seasonChecklist/glimmer.png"
import seasonLeaderboard from "../../../public/img/tools/seasonChecklist/leaderboard.png"

export const seasonChecklist: ITool = {
	title: "Seasonal Checklist",
	description: "Get ready for the next season and use this to check your preparation progress",
  platforms: [platform.Website],
  link: "https://destinyrecipes.com/checklist",
	image: seasonWebsiteDisplay,
	imageMobile: seasonMobileDisplay,
  images: [seasonWebsiteDisplay, seasonXp, seasonMaterial, seasonGlimmer, seasonLeaderboard]
};


const checklist: IToolType = {
	name: "Checklist",
	tools: [d2Checklist, seasonChecklist],
};

export default checklist;


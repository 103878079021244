import { IToolType, ITool, platform } from "@/types/toolCollection"

import dtWebsiteDisplay from "../../../public/img/tools/destinyTracker/websiteDisplay.png"
import dtHome from "../../../public/img/tools/destinyTracker/home.png"
import dtPerformace from "../../../public/img/tools/destinyTracker/performance.png"
import dtShowcase from "../../../public/img/tools/destinyTracker/showcase.png"

export const destinyTracker: ITool = {
	title: "Destiny Tracker",
	description: "See your personal performance inside the crucible with an all inclusive overfiew",
	platforms: [platform.Website],
	link: "https://google.com/",
	image: dtWebsiteDisplay,
	imageMobile: dtWebsiteDisplay,
	images: [dtHome, dtPerformace, dtShowcase]
};


import redWebsiteDisplay from "../../../public/img/tools/redrix/websiteDisplay.webp"
import redMap from "../../../public/img/tools/redrix/map.webp"
import redGraph from "../../../public/img/tools/redrix/graph.webp"
import redScores from "../../../public/img/tools/redrix/scores.webp"
import redIndividualScore from "../../../public/img/tools/redrix/individualScore.webp"
import redLeaderboard from "../../../public/img/tools/redrix/leaderboard.webp"

export const Redrix: ITool = {
	title: "Redrix",
	description: "See your personal performance inside the crucible with an all inclusive overfiew",
	platforms: [platform.Mobile],
	link: "https://play.google.com/store/apps/details?id=io.redrix.app&hl=en&gl=US",
	image: redWebsiteDisplay,
	imageMobile: redWebsiteDisplay,
	images: [redWebsiteDisplay, redMap, redGraph, redScores, redIndividualScore, redLeaderboard]
};



const statTracker: IToolType = {
	name: "Stat Tracker",
	tools: [destinyTracker, Redrix],
};

export default statTracker;
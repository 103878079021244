import { IToolType, ITool, platform } from "@/types/toolCollection";

import trWebsiteDisplay from "../../../public/img/tools/trialsReport/websiteDisplay.png"
import trPlayerDisplay1 from "../../../public/img/tools/trialsReport/playerDisplay1.png"
import trPlayerDisplay2 from "../../../public/img/tools/trialsReport/playerDisplay2.png"

export const trialsReport: ITool = {
	title: "Trials Report",
	description: "Easily look up your trials opponents to see their past performance",
  platforms: [platform.Website, platform.Mobile],
  link: "https://destinytrialsreport.com/",
	image: trWebsiteDisplay,
	imageMobile: trWebsiteDisplay,
  images: [trPlayerDisplay1, trPlayerDisplay2]
};

const crucibleLookup: IToolType = {
	name: "Crucible Lookup",
	tools: [trialsReport],
};

export default crucibleLookup;



























import { Prop, Component, Vue } from "vue-property-decorator";
import BungieApi from "@/util/BungieApi";
import OAuth from "@/util/OAuth";

@Component
export default class Account extends Vue {
  isLoggedIn = false;
  accountName = '';

  mounted() {
    if (BungieApi.isLoggedIn()) this.setUpAccount()
    OAuth.onLogin(this.setUpAccount)
    OAuth.onLogout(() => this.isLoggedIn = false)
  }

  async setUpAccount() {
    this.isLoggedIn = true
    const res = await BungieApi.getUserData()
    if (res.ErrorStatus != "Success") this.isLoggedIn = false
    else this.accountName = res.Response.displayName
    setTimeout(this.setDropdownWidth, 50)
  }

  setDropdownWidth() {
    // @ts-ignore
    const parentWidth = document.querySelector("div[name='account']").offsetWidth
    console.log(`parentW: ${parentWidth}`)
    // @ts-ignore
    document.querySelector("div[name='name-display']+ul").style.width = `${parentWidth}px`;
  }

  logIn() {
    OAuth.logIn()
  }
  
  logOut() {
    OAuth.logOut()
  }
  
  showAccountDropdown() {
    // @ts-ignore
    document.querySelector("div[name='account'] .ul")[0].display = "inline";
  }
}

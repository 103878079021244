




































































import {Component, Prop, Vue} from "vue-property-decorator";
import {ITool, IToolType} from "@/types/toolCollection";
import Gallery from '../gallery.vue';

@Component({
	components: {
		gallery: Gallery
	},
})
export default class Tool extends Vue {
	@Prop() content!: IToolType;
	displayTool!: ITool;
	showAlternative = false;

	created() {
		this.displayTool = this.content.tools[0];
		this.$forceUpdate
	}

	toggleAlternative() {
		this.showAlternative = !this.showAlternative;
	}

	showTool(tool: ITool) {
		this.displayTool = tool;
		this.showAlternative = false;
	}

	showGallery() {
		(this.$refs.gallery as Gallery).show();
	}

	showMobileGallery() {
		(this.$refs.galleryMobile as Gallery).show();
	}
}

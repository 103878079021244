import { IToolCollections } from '@/types/toolCollection';

import generalIcon from "../../public/img/icons/orbit.png";
import vangaurdIcon from "../../public/img/icons/vangaurd.png";
import crucibleIcon from "../../public/img/icons/crucible.png";
import seasonalIcon from "../../public/img/icons/seasonal.png";

//General
import itemManager from "./tools/itemManager";
import itemDatabase from "./tools/itemDatabase";
import loreDatabase from "./tools/loreDatabase";
import checklist from "./tools/checklist";
import xurLocator from "./tools/xurLocator";
import assistant from "./tools/assistant";
//Vangaurd
import mapExplorer from "./tools/mapExplorer";
import vanguardLookup from "./tools/vanguardLookup";
//Crucible
import crucibleLookup from "./tools/crucibleLookup";
import statTracker from "./tools/statTracker";
//Seasonal
import menagerieRecipes from "./tools/menagerieRecipes";

const toolCollections: IToolCollections = {
	general: {
		icon: generalIcon,
		link: "collectionGeneral",
		tools: [itemManager, itemDatabase, loreDatabase, checklist, xurLocator, assistant],
	},
	vangaurd: {
		icon: vangaurdIcon,
		link: "collectionVangaurd",
		tools: [mapExplorer, vanguardLookup],
	},
	crucible: {
		icon: crucibleIcon,
		link: "collectionCrucible",
		tools: [statTracker, crucibleLookup],
	},
	seasonal: {
		icon: seasonalIcon,
		link: "collectionSeasonal",
		tools: [menagerieRecipes],
	},
};

export default toolCollections;

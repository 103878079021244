import { IToolType, ITool, platform } from "@/types/toolCollection";

import ishtarHome from "../../../public/img/tools/ishtarCollective/home.png"
import ishtarTimeline from "../../../public/img/tools/ishtarCollective/timeline.png"
import ishtarLorePost from "../../../public/img/tools/ishtarCollective/lorePost.png"
import ishtarEpisodes from "../../../public/img/tools/ishtarCollective/episodes.png"
import ishtarWebsiteDisplay from "../../../public/img/tools/ishtarCollective/websiteDisplay.png"
import ishtarMobileDisplay from "../../../public/img/tools/ishtarCollective/mobileHome.png"


export const ishtarCollective: ITool = {
	title: "Ishtar Collective",
	description: "A fast collection of destinies lore presented using blogs, graphics, podcasts & more",
  platforms: [platform.Website],
  link: "https://www.ishtar-collective.net",
	image: ishtarWebsiteDisplay,
	imageMobile: ishtarMobileDisplay,
  images: [ishtarHome, ishtarTimeline, ishtarLorePost, ishtarEpisodes]
};

const loreDatabase: IToolType = {
	name: "Lore Database",
	tools: [ishtarCollective],
};

export default loreDatabase;

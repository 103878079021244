import { IToolType, ITool, platform } from "@/types/toolCollection";

import lightWebsiteDisplay from "../../../public/img/tools/lightGG/websiteDisplay.png";
import lightWeaponList from "../../../public/img/tools/lightGG/weaponList.png";
import lightItemDisplay1 from "../../../public/img/tools/lightGG/itemDisplay1.png";
import lightItemDisplay2 from "../../../public/img/tools/lightGG/itemDisplay2.png";
import lightSealTracker from "../../../public/img/tools/lightGG/sealTracker.png";

export const lightGG: ITool = {
	title: "Light.gg",
	description: "All incluse & up-to-date database of all the collectable items & achievements inside of destiny 2",
  platforms: [platform.Website],
  link: "https://www.light.gg/",
	image: lightWebsiteDisplay,
	imageMobile: lightWebsiteDisplay,
  images: [lightWeaponList, lightItemDisplay1, lightItemDisplay2, lightSealTracker]
};


import d2SetsWebsiteDisplay from "../../../public/img/tools/destinySets/websiteDisplay.png";
import d2SetsWeapons from "../../../public/img/tools/destinySets/weapons.png";
import d2SetsSeasonal from "../../../public/img/tools/destinySets/seasonal.png";
import d2SetsCatalysts from "../../../public/img/tools/destinySets/catalysts.png";
import d2SetsMods from "../../../public/img/tools/destinySets/mods.png";

export const d2Sets: ITool = {
	title: "Destiny Sets",
	description: "See all the collectable gear & cosmetics and see which ones you are missing",
  platforms: [platform.Website],
  link: "https://destinysets.com/",
	image: d2SetsWebsiteDisplay,
	imageMobile: d2SetsWebsiteDisplay,
  images: [d2SetsWeapons, d2SetsSeasonal, d2SetsCatalysts, d2SetsMods]
};

const itemDatabase: IToolType = {
	name: "Item Database",
	tools: [lightGG, d2Sets],
};



export default itemDatabase;

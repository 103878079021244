// import { ServerResponse, } from "bungie-api-ts/common"
import { BungieMembershipType, DestinyComponentType, DestinyManifestComponentName, DestinyManifestSlice, getDestinyManifest, getDestinyManifestSlice, getProfile, HttpClientConfig } from "bungie-api-ts/destiny2";
import { getBungieNetUserById, getMembershipDataForCurrentUser } from "bungie-api-ts/user"
import _ from 'lodash';
import OAuth from './OAuth';
import Config from "@/util/Config"
import { GroupUserInfoCard } from "bungie-api-ts/groupv2/interfaces";


class BungieApi {
  private static publicHeaders = { 'X-API-KEY': Config.apiKey }
  private static manifestSlices: DestinyManifestSlice<any> = []
  private static primaryMembership: GroupUserInfoCard;

  public static async publicHttpClient(config: HttpClientConfig) {
    return (await fetch(BungieApi.createUrl(config), { ...config, ...BungieApi.publicHeaders })).json()
  }

  private static async privateHttpClient(config: HttpClientConfig) {
    return (await fetch(BungieApi.createUrl(config), { 
      ...config,
      headers: { ...BungieApi.publicHeaders,
        Authorization: `Bearer ${OAuth.getAccessToken()}`
      }
    })).json()
  }

  private static createUrl(config: HttpClientConfig) {
    let url = config.url
    if (config.params) url += `?${new URLSearchParams(config.params).toString()}`
    return url
  }

  public static isLoggedIn() {
    return OAuth.isLoggedIn()
  }

  private static validateLoggedIn() {
    if (!OAuth.isLoggedIn()) throw Error("User is not logged in")
  }

  public static async getManifest<T extends DestinyManifestComponentName[]>(...tableNames: T): Promise<DestinyManifestSlice<T>> {
    const notLoadedManifestSlices = tableNames.filter(
      tableName => { 
        return !this.manifestSlices[tableName]
      }
    )

    if (notLoadedManifestSlices.length !== 0) { 
      const loadedManifesSlices = await this.loadManifest(notLoadedManifestSlices)
      this.manifestSlices = Object.assign(this.manifestSlices, loadedManifesSlices)
    }

    return _.pick(this.manifestSlices, tableNames)
  }

  private static async loadManifest(tableNames: DestinyManifestComponentName[]) {
    const destinyManifest = await (await getDestinyManifest(this.publicHttpClient)).Response
    return await getDestinyManifestSlice(this.publicHttpClient, {
      destinyManifest,
      tableNames,
      language: 'en'
    });
  }

  public static async getUserData() {
    this.validateLoggedIn()
    return await getBungieNetUserById(this.privateHttpClient, {
      id: OAuth.getMembershipId()
    })
  }

  public static async getMembershipsForCurrentUser() {
    this.validateLoggedIn()
    return await getMembershipDataForCurrentUser(this.privateHttpClient)
  }

  public static async getPrimaryMembershipsForCurrentUser(): Promise<GroupUserInfoCard> { 
    if (BungieApi.primaryMembership) return BungieApi.primaryMembership

    let membersips = (await this.getMembershipsForCurrentUser()).Response
    let primaryMembershipId = membersips.primaryMembershipId
    BungieApi.primaryMembership = !primaryMembershipId 
      ? membersips.destinyMemberships[0]
      : membersips.destinyMemberships.filter((membership) => membership.membershipId == primaryMembershipId)[0]
    return BungieApi.primaryMembership
  }

  public static async getProfile(characterComponents: DestinyComponentType[]) {
    this.validateLoggedIn()
    const primaryMembership = await BungieApi.getPrimaryMembershipsForCurrentUser()
    return await getProfile(this.privateHttpClient, {
      components: characterComponents,
      destinyMembershipId: primaryMembership.membershipId,
      membershipType: primaryMembership.membershipType
    })
  }

  public static async getItems() {
    const profile = (await this.getProfile([DestinyComponentType.ItemInstances, DestinyComponentType.ProfileInventories, DestinyComponentType.CharacterInventories, DestinyComponentType.CharacterEquipment])).Response
    let items = profile.profileInventory.data!!.items
    Object.values(profile.characterInventories.data!!).forEach(character => {items = items.concat(character.items) })
    Object.values(profile.characterEquipment.data!!).forEach(character => {items = items.concat(character.items) })

    return {
      instances: profile.itemComponents.instances.data!!,
      items: items
    }
  }

}


export default BungieApi



















import { Component, Prop, Vue } from "vue-property-decorator";
import { IToolCollections } from "../../types/toolCollection";

@Component({
	components: {},
})
export default class Legend extends Vue {
	@Prop() tools!: IToolCollections;
	disableButtons = false;
	currentActive!: HTMLElement | null;
	legendEls!: HTMLCollection;

	mounted() {
		this.legendEls = this.$el.children;
	}

	public activeButton(id: number): void {
		if (this.disableButtons) return;

		if (this.currentActive) this.deactivateButton(this.currentActive);
		const newActiveButton = this.legendEls[id] as HTMLElement;
		this.activateButton(newActiveButton);
		this.currentActive = newActiveButton;
	}

	private deactivateButton(button: HTMLElement) {
		button.classList.remove("active");
		button.children[0]?.classList.remove("active");
	}

	private activateButton(button: HTMLElement) {
		button.classList.add("active");
		button.children[0]?.classList.add("active");
	}

	gotoCollection(key: string) {
		const index = Object.keys(this.tools).findIndex((item) => item === key);

		this.enableButtons();
		this.activeButton(index);
		this.temporarelyDisableButtons(500);
		this.$emit("GotoCollection", index);
	}

	enableTimeout!: NodeJS.Timeout;
	temporarelyDisableButtons(duration: number) {
		this.disableButtons = true;
		if (this.enableTimeout) clearTimeout(this.enableTimeout);
		this.enableTimeout = setTimeout(() => (this.disableButtons = false), duration);
	}

	enableButtons() {
		this.disableButtons = false;
		if (this.enableTimeout) clearTimeout(this.enableTimeout);
	}
}

export type IToolCollections = {
	general: IToolCollection;
	vangaurd: IToolCollection;
	crucible: IToolCollection;
	seasonal: IToolCollection;
	[index: string]: IToolCollection;
};

export type IToolCollection = {
	icon: unknown;
	link: string;
	tools: IToolType[];
};

export type IToolType = {
		name: string;
		tools: ITool[];
}

export type ITool = {
	title: string;
	description: string;
	platforms: platform[];
	image: unknown;
	imageMobile: unknown;
	images: unknown[];
	link: string;
};

export enum platform {
	Website = "Website",
	Desktop = "Desktop app",
	Mobile = "Mobile app",
	Discord = "Discord bot",
	Speaker = "Smart speaker"
}

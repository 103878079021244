































import {Component, Prop, Vue} from "vue-property-decorator";
import arrow from "../../public/img/icons/arrow-button.png";

@Component({
	components: {},
})
export default class Gallery extends Vue {
	@Prop() images!: string[];
	currentImg = 0;
	isHidden = true;

	arrowIcn = arrow;

	nextImg() {
		this.currentImg = (this.currentImg + 1) % (this.images.length);
	}

	prevImg() {
		const imageAmount = this.images.length;
		this.currentImg = (this.currentImg + imageAmount - 1) % imageAmount;
	}

	public show() {
		console.log('shown')
		this.isHidden = false;
	}

	private hide() {
		this.isHidden = true;
	}
}

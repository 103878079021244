






















import { Component, Prop, Vue } from "vue-property-decorator";
import Tool from "./tool.vue";
import { IToolCollections } from "@/types/toolCollection";

@Component({
	components: {
		tool: Tool,
	},
})
export default class ToolsDisplay extends Vue {
	@Prop(Object) tools!: IToolCollections;
	collectionHeights: number[] = [];
	currentCollection = 0;

	mounted() {
		this.$el.addEventListener("scroll", this.onScroll);
		this.$emit("CollectionChange", 0);
		setTimeout(this.initCollectionHeights, 200); //TODO find a way to hook/call this init after render without a timeout
	}

	initCollectionHeights() {
		this.collectionHeights = [];
		for (const child of this.$el.childNodes) {
			const offset = (child as HTMLElement).offsetTop - 300;
			this.collectionHeights.push(offset);
		}
	}

	public scrollTo(collectionId: number) {
		const scrollToHeight = (this.$el.children[collectionId] as HTMLElement).offsetTop - 60;
		this.$el.scrollTop = scrollToHeight;
	}

	onScroll(): void {
		const currentScroll = this.$el.scrollTop;
		for (let index = this.collectionHeights.length - 1; index >= 0; index--) {
			const checkpoint = this.collectionHeights[index];
			if (currentScroll > checkpoint) {
				this.shownCollection(index);
				break;
			}
		}
	}

	shownCollection(id: number) {
		if (this.currentCollection !== id) {
			this.currentCollection = id;
			this.$emit("CollectionChange", id);
		}
	}

	capitalizeFirst(text: string): string {
		const capital = text[0].toUpperCase();
		const lower = text.substring(1);
		return capital + lower;
	}
}

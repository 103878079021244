import { IToolType, ITool, platform } from "@/types/toolCollection";

import lowWebsiteDisplay from "../../../public/img/tools/lowlidevMaps/websiteDisplay.png"
import lowHub from "../../../public/img/tools/lowlidevMaps/hub.png"
import lowMoon from "../../../public/img/tools/lowlidevMaps/moon.png"
import lowDreamingCity from "../../../public/img/tools/lowlidevMaps/dreamingCity.png"
import lowEarth from "../../../public/img/tools/lowlidevMaps/earth.png"

export const lowlidevMaps: ITool = {
	title: "Lowlidev maps",
	description: "Explore all of the destiny 2 patrols spaces with this interactive map that tracks all you progress, from quests to collectables",
  platforms: [platform.Website],
  link: "https://lowlidev.com.au/destiny/maps/",
	image: lowWebsiteDisplay,
	imageMobile: lowWebsiteDisplay,
  images: [lowHub, lowMoon, lowDreamingCity, lowEarth]
};

const mapExplorer: IToolType = {
	name: "Map explorer",
	tools: [lowlidevMaps],
};

export default mapExplorer;

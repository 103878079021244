import { IToolType, ITool, platform } from "@/types/toolCollection";

import charleWebsiteDisplay from "../../../public/img/tools/charlemagne/websiteDisplay.png";
import charleCommands from "../../../public/img/tools/charlemagne/commands.png";
import charleCommandsShowcase1 from "../../../public/img/tools/charlemagne/commandShowcase1.png";
import charleCommandsShowcase2 from "../../../public/img/tools/charlemagne/commandShowcase2.png";
import charleTrialsAnalytics from "../../../public/img/tools/charlemagne/trialsAnalytics.png";

export const charlemagne: ITool = {
	title: "Charlemagne",
	description: "A destiny analytic assistant that you can intergrate into your discord server to help you with ",
  platforms: [platform.Discord],
  link: "https://warmind.io/",
	image: charleWebsiteDisplay,
	imageMobile: charleWebsiteDisplay,
  images: [charleCommands, charleCommandsShowcase1, charleCommandsShowcase2, charleTrialsAnalytics]
};


import companionWebsiteDisplay from "../../../public/img/tools/companion/websiteDisplay.webp";
import compantionBounties from "../../../public/img/tools/companion/bounties.webp";
import compantionCharacters from "../../../public/img/tools/companion/characters.webp";
import compantionItems from "../../../public/img/tools/companion/items.webp";
import compantionFireteam from "../../../public/img/tools/companion/fireteam.webp";
import compantionNews from "../../../public/img/tools/companion/news.webp";

export const companion: ITool = {
	title: "Destiny Companion App",
	description: "The official Destiny 2 Companion app keeps you connected to your Destiny adventure wherever life takes you. Basically, bungie.net as an app",
  platforms: [platform.Mobile],
  link: "https://play.google.com/store/apps/details?id=com.bungieinc.bungiemobile&hl=en&gl=US",
	image: companionWebsiteDisplay,
	imageMobile: companionWebsiteDisplay,
  images: [companionWebsiteDisplay, compantionBounties, compantionCharacters, compantionItems, compantionFireteam, compantionNews]
};


import wnWebsiteDisplay from "../../../public/img/tools/warmindNetwork/websiteDisplay.png";
import wnFamily from "../../../public/img/tools/warmindNetwork/family.jpg";

export const warmindNetwork: ITool = {
	title: "Warmind Network",
	description: "This smart speaker app makes it simple and straight forward to manage your Destiny 2 in-game inventory without taking your hands off the keyboard and it also tells dad jokes",
  platforms: [platform.Speaker],
  link: "https://www.amazon.com/dp/B076FLLNSV/ref=cm_sw_su_dp",
	image: wnWebsiteDisplay,
	imageMobile: wnWebsiteDisplay,
  images: [wnFamily]
};


const assistant: IToolType = {
	name: "Assistant",
	tools: [companion, charlemagne, warmindNetwork],
};

export default assistant;

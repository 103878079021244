






































import { Component, Vue } from "vue-property-decorator";
import account from "./account.vue";

@Component({
	components: {
		Account: account,
	},
})
export default class Navbar extends Vue {
	tools = [
		{
			name: "leveling",
			link: "/leveler",
		}
	];
}

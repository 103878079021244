import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import mockCalcCharacters from "@/data/mock/calculatorCharacters"

Vue.use(Vuex);

const vuexLocaleStorage = new VuexPersistence({
	key: 'vuex',
	storage: window.localStorage,
	// reducer: (state: any) => ({})
});

const emptyToken = {
	accessToken: undefined,
	accessType: undefined,
	expiresOn: undefined,
	membershipId: undefined
};

export default new Vuex.Store({
	state: {
		route: '',
		auth: {
			state: '',
			token: emptyToken,
		},
		calculator: { 
			characters: null, //mockCalcCharacters.characters,
			active: 0,
		}
	},
	mutations: {
		setRoute: (state, data) => state.route = data,
		setAuthState: (state, data) => state.auth.state = data,
		setAuthToken: (state, data) => state.auth.token = data,
		setCalcCharacters: (state, data) => state.calculator.characters = data,
		setCalcActiveCharacter: (state, data) => state.calculator.active = data,
		clearToken: state => state.auth.token = emptyToken,
	},
	actions: {
	},
	modules: {},
	getters: {
		getAuthState: state => state.auth.state,
		getAuthToken: state => state.auth.token,
		calcCharacters: state => state.calculator.characters,
		calcActiveCharacter: state => state.calculator.active
	},
	plugins: [vuexLocaleStorage.plugin]
});











import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Scrollbar extends Vue {
	mouseOnScrollbar = false;

	mounted() {
		this.setScrollHeight();
		this.initMousePositionCheck();
		this.syncScrollers();
	}

	setScrollHeight() {
		setTimeout(() => {
			const scroller = document.getElementById("toolScrollerSize");
			const displayHeight = document.getElementById("toolCollection")?.scrollHeight;
			if (scroller) scroller.style.height = `${(displayHeight || 50) - 100}px`;
		}, 200);
	}

	initMousePositionCheck() {
		this.$el.addEventListener("mouseenter", () => (this.mouseOnScrollbar = true));
		this.$el.addEventListener("mouseleave", () => (this.mouseOnScrollbar = false));
	}

	syncScrollers() {
		const display = document.getElementById("toolCollection");
		const scrollbar = document.getElementById("toolScroller");
		if (!display || !scrollbar) return;

		display.addEventListener("scroll", () => this.scrollScrollbar(scrollbar, display.scrollTop));
		scrollbar.addEventListener("scroll", () => this.scrollDisplay(display, scrollbar.scrollTop));
	}

	async scrollScrollbar(scrollbar: HTMLElement, scrollTo: number) {
		if (this.mouseOnScrollbar) return;

		this.scrollElementTo(scrollbar, scrollTo);
	}

	async scrollDisplay(display: HTMLElement, scrollTo: number) {
		const mouseNotOnScrollbar = !this.mouseOnScrollbar;
		if (mouseNotOnScrollbar) return;

		display.style.scrollBehavior = "auto";
		this.scrollElementTo(display, scrollTo);
		display.style.scrollBehavior = "smooth";
	}

	scrollElementTo(element: HTMLElement, scrollTo: number) {
		element.scrollTop = scrollTo;
	}
}

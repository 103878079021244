import { IToolType, ITool, platform } from "@/types/toolCollection"

import destinyItemManagerImg from "../../../public/img/tools/destinyItemManager/DestinyItemManager.png"
import destinyItemManagerWideImg from "../../../public/img/tools/destinyItemManager/DestinyItemManager - Copy.jpg"
import DIMDesktopMain from "../../../public/img/tools/destinyItemManager/desktopMain.png"
import DIMDesktopLoadout from "../../../public/img/tools/destinyItemManager/desktopLoadout.png"
import DIMDesktopLoadouts from "../../../public/img/tools/destinyItemManager/desktopLoadouts.png"

export const destinyItemManager: ITool = {
	title: "Destiny Item Manager",
	description: "An all in one tool to manage you weapons, armor & much more",
  platforms: [platform.Website, platform.Mobile, platform.Desktop],
  link: "https://app.destinyitemmanager.com",
	image: destinyItemManagerImg,
	imageMobile: destinyItemManagerWideImg,
  images: [DIMDesktopMain, DIMDesktopLoadout, DIMDesktopLoadouts]
};


import icWebsiteDisplay from "../../../public/img/tools/ishtarCommander/websiteDisplay.webp"
import icInventory from "../../../public/img/tools/ishtarCommander/inventory.webp"
import icDetails from "../../../public/img/tools/ishtarCommander/details.webp"
import icBounties from "../../../public/img/tools/ishtarCommander/bounties.webp"
import icProgress from "../../../public/img/tools/ishtarCommander/progress.webp"
import icTriumphs from "../../../public/img/tools/ishtarCommander/triumphs.webp"

export const ishtarCommander: ITool = {
	title: "Ishtar Commander",
	description: "Tap into your Destiny 2 Guardian's weapons, gear, vault, and inventory all from your devices",
  platforms: [platform.Desktop, platform.Mobile],
  link: "https://www.microsoft.com/en-us/p/ishtar-commander-for-destiny/9pk2z4mf5mns?activetab=pivot:overviewtab",
	image: icWebsiteDisplay,
	imageMobile: icWebsiteDisplay,
  images: [icWebsiteDisplay, icInventory, icDetails, icBounties, icProgress, icTriumphs]
};


import vmWebsiteDisplay from "../../../public/img/tools/vaultManager/websiteDisplay.webp"
import vmTransfer from "../../../public/img/tools/vaultManager/transfer.webp"
import vmReview from "../../../public/img/tools/vaultManager/review.webp"
import vmSets from "../../../public/img/tools/vaultManager/sets.webp"
import vmProgress from "../../../public/img/tools/vaultManager/progress.webp"

export const vaultManager: ITool = {
	title: "Vault Manager",
	description: "The Vault is a FREE item manager application for the massively popular shared-world shooter games: Destiny 2 and Destiny 1",
  platforms: [platform.Mobile],
  link: "https://play.google.com/store/apps/details?id=com.crocusgames.destinyinventorymanager&hl=en&gl=US",
	image: vmWebsiteDisplay,
	imageMobile: vmWebsiteDisplay,
  images: [vmWebsiteDisplay, vmTransfer, vmReview, vmSets, vmProgress]
};

const itemManager: IToolType = {
	name: "Item Manager",
	tools: [destinyItemManager, ishtarCommander, vaultManager],
};

export default itemManager;

import { IToolType, ITool, platform } from "@/types/toolCollection";

import mcWebsiteHome from "../../../public/img/tools/menagerieCalculator/websiteDisplay.png";
import mcMobileHome from "../../../public/img/tools/menagerieCalculator/mobileDisplay.png";
import mcItemCrafting from "../../../public/img/tools/menagerieCalculator/itemCrafting.png";
import mcRuneCrafting from "../../../public/img/tools/menagerieCalculator/runeCrafting.png";

export const menagerieCalculator: ITool = {
	title: "Menagerie Calculator",
	description: "An interactive recipie list to find out how to craft your desired items",
  platforms: [platform.Website],
  link: "https://menagerie-calculator.now.sh/",
	image: mcWebsiteHome,
	imageMobile: mcMobileHome,
  images: [mcItemCrafting, mcRuneCrafting]
};

const menagerieRecipes: IToolType = {
	name: "Menagerie Recipes",
	tools: [menagerieCalculator],
};

export default menagerieRecipes;










import { Component, Vue } from "vue-property-decorator";
import Nav from "./components/nav/navbar.vue";

import defaultImg from "../public/img/background-not-found.jpg";
import homeImg from "../public/img/dreaming-city_hub.jpg";
import aboutImg from "../public/img/europa_calculator.jpg";
import BungieApi from "./util/BungieApi";

const backgrounds: { [index: string]: unknown } = {
	default: defaultImg,
	home: homeImg,
	about: aboutImg,
};

@Component({
	components: {
		Nav,
	},
})
export default class App extends Vue {

	changeBackground(background: string): void {
		const img = backgrounds[background] ? backgrounds[background] : backgrounds.default;

		const rootEl = document.querySelector("html") as HTMLHtmlElement;
		if (rootEl) rootEl.style.backgroundImage = `url(${img})`;
	}
}

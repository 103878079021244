import store from '@/store';
import crypto from 'crypto-browserify';
import url from 'url';
import axios from 'axios';
import Config from './Config'
import { EventEmitter } from 'events'

const AUTH_URL = "https://www.bungie.net/en/oAuth/authorize";
const TOKEN_URL = "https://www.bungie.net/platform/app/oauth/token/";
const REFRESH_URL = TOKEN_URL;


export default class OAuth {
    private static em = new EventEmitter()
    private static autoLogout: NodeJS.Timeout

    public static logIn() {
        const state = crypto.randomBytes(20).toString('hex') as string
        store.commit('setAuthState', state)
        const userAuthUrl = `${AUTH_URL}?client_id=${Config.clientId}&response_type=code&state=${state}`
        window.location.href = userAuthUrl
    }

    public static logOut() {
        store.commit('setAuthToken', null)
        store.commit('setAuthState', null)
        this.clearAutoLogout()
        OAuth.em.emit('loggedOut')
    }

    public static isLoggedIn(): boolean {
        const authToken = store.getters.getAuthToken
        return authToken && Object.keys(authToken).length > 0 && !this.isExpired()
    }

    private static isExpired(): boolean {
        return Date.now() > store.getters.getAuthToken.expiresOn
    }

    public static getAccessToken(): string {
        return store.getters.getAuthToken.access_token
    }

    public static getMembershipId(): string {
        return store.getters.getAuthToken.membership_id
    }

    public static expiresOn(): number {
        return store.getters.getAuthToken.expiresOn as number
    }

    public static onLogin(cb: () => void) {
        this.em.on('loggedIn', cb)
    }

    public static onLogout(cb: () => void) {
        this.em.on('loggedOut', cb)
    }

    public static initAutoLogout() {
        this.clearAutoLogout()
        this.autoLogout = setTimeout(() => {
            OAuth.logOut()
        }, this.expiresOn() - Date.now()) 
    }

    private static clearAutoLogout() {
        if (this.autoLogout) clearTimeout(this.autoLogout)
    }

    public static async logInCallback(code: string, state: string) {
        console.log({code, state, currState: store.getters.getAuthState});
        const savedState = store.getters.getAuthState;
        if (state === savedState) await this.requestAccessToken(code);
    }

    private static async requestAccessToken(code: string): Promise<void> {
        const params = `client_id=${Config.clientId}&code=${code}&grant_type=authorization_code&redirect_uri=https://localhost:8080/bungie/callback`;
        try {
            const response = await axios.post(TOKEN_URL, params);
            response.data.expiresOn = Date.now() + (response.data.expires_in * 1000)
            store.commit('setAuthToken', response.data);
            this.em.emit('loggedIn')
            this.initAutoLogout()
        } catch (e) {
            console.log("accesToken handling error", e);
        }
    }
}


//init auto-logout if loggen in
if (OAuth.isLoggedIn()) {
    OAuth.initAutoLogout()
}
import { IToolType, ITool, platform } from "@/types/toolCollection";

import grWebsiteDisplay from "../../../public/img/tools/guardianReport/websiteDisplay.png"
import grPlayerDisplay1 from "../../../public/img/tools/guardianReport/playerDisplay1.png"
import grPlayerDisplay2 from "../../../public/img/tools/guardianReport/playerDisplay2.png"

export const guardianReport: ITool = {
	title: "Guardian Report",
	description: "Easily look up yourself or your fellow guardians to see how they are faring inside of destiny ",
  platforms: [platform.Website],
  link: "https://guardian.report/",
	image: grWebsiteDisplay,
	imageMobile: grWebsiteDisplay,
  images: [grPlayerDisplay1, grPlayerDisplay2]
};

const vanguardLookup: IToolType = {
	name: "Vanguard Lookup",
	tools: [guardianReport],
};

export default vanguardLookup;
